import styled from '@emotion/styled';

export const Table = styled.table`
  margin: ${({ theme }) => theme.space.xs}px;
  border-spacing: 0 ${({ theme }) => theme.space.xs}px;

  td {
    padding-right: ${({ theme }) => theme.space.s}px;
  }
`;

export const TH = styled.th`
  text-align: left;
`;

export const TBody = styled.tbody``;
export const BodyTR = styled.tr`
  height: 100px;
`;
