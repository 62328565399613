import React from 'react';
import Head from 'next/head';
import { ThemeProvider, Box, H1 } from '@updater/ui-uds';
import { Menu } from '../components';

export default function MainApp({ Component, pageProps }) {
  return (
    <>
      <Head>
        <title>Updater</title>
        <link
          rel="shortcut icon"
          href="https://d30c45h9cuv4p4.cloudfront.net/favicon-060719.ico"
        />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      </Head>
      <ThemeProvider>
        <Menu />
        <Component {...pageProps} />
      </ThemeProvider>
    </>
  );
}
