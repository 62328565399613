import { TAddress } from '@updater/ui-widgets';
import { Text, ButtonLink, Box, IconButton } from '@updater/ui-uds';
import { X } from 'phosphor-react';
import { AccountData } from 'types';
import Cookies from 'js-cookie';
import { Table, TH, BodyTR, TBody } from './Table';

function addressToString(address: TAddress): string {
  return `${address.street}${address.unit ? `, ${address.unit}` : ''}
${address.city}, ${address.state} ${address.postalCode}`;
}

const ROOT_DOMAIN = 'updater.com';
function setupLogin({
  accessToken,
  client,
  uid,
}: {
  accessToken: string;
  client: string;
  uid: string;
}) {
  Cookies.set('access-token', accessToken, { domain: ROOT_DOMAIN });
  Cookies.set('client', client, { domain: ROOT_DOMAIN });
  Cookies.set('uid', uid, { domain: ROOT_DOMAIN });
}

export const UserRow = ({
  user,
  removeUser,
}: {
  user: AccountData;
  removeUser: () => void;
}) => {
  return (
    <BodyTR>
      <td>
        <Text variant="s">
          <Box>{user.flow === ' ' ? 'None' : user.flow}</Box>
          <Box>
            {Array.isArray(user.dimensions)
              ? user.dimensions.join(',')
              : user.dimensions}
          </Box>
        </Text>
      </td>
      <td>
        <Text variant="s">
          {user.firstName} {user.lastName}
        </Text>
      </td>
      <td>
        <Text variant="s">{user.email}</Text>
      </td>
      <td>
        <Text variant="s">{user.password}</Text>
      </td>
      <td>
        <Text variant="s">{user.uuid}</Text>
      </td>
      <td width="175px">
        <Text variant="s">{addressToString(user.fromAddress)}</Text>
      </td>
      <td width="175px">
        <Text variant="s">{addressToString(user.toAddress)}</Text>
      </td>
      <td>
        <ButtonLink
          href="https://consumer-app.staging.updater.com"
          target="_blank"
          type="button"
          mt="s"
          onClick={() => setupLogin(user)}
          size="s"
        >
          Login to Consumer App
        </ButtonLink>
      </td>
      <td width="30px">
        <IconButton type="button" size="s" onClick={removeUser} icon={<X />} />
      </td>
    </BodyTR>
  );
};

export const UsersTable = ({
  users,
  removeUser,
}: {
  users: AccountData[];
  removeUser: (id: string) => void;
}) => {
  return (
    <Table>
      <thead>
        <tr>
          <TH>
            <Text variant="sBold">Flows</Text>
          </TH>
          <TH>
            <Text variant="sBold">Name</Text>
          </TH>
          <TH>
            <Text variant="sBold">Email</Text>
          </TH>
          <TH>
            <Text variant="sBold">Password</Text>
          </TH>
          <TH>
            <Text variant="sBold">UUID</Text>
          </TH>
          <TH>
            <Text variant="sBold">From Address</Text>
          </TH>
          <TH>
            <Text variant="sBold">To Address</Text>
          </TH>
          <TH>
            <Text variant="sBold">Login as user</Text>
          </TH>
          <TH>
            <Text variant="sBold">Remove User</Text>
          </TH>
        </tr>
      </thead>
      <TBody>
        {users.map((user) => (
          <UserRow
            key={user.email}
            user={user}
            removeUser={() => removeUser(user.email)}
          />
        ))}
      </TBody>
    </Table>
  );
};
