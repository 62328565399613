import { TAddress } from '@updater/ui-widgets';
import { Text, ButtonLink, Box, IconButton } from '@updater/ui-uds';
import { X } from 'phosphor-react';
import { InviteData } from 'types';
import { Table, TH, BodyTR, TBody } from './Table';

function addressToString(address: TAddress): string {
  return `${address.street}${address.unit ? `, ${address.unit}` : ''}
${address.city}, ${address.state} ${address.postalCode}`;
}

export const InviteRow = ({
  invite,
  removeInvite,
}: {
  invite: InviteData;
  removeInvite: () => void;
}) => {
  return (
    <BodyTR>
      <td>
        <Text variant="s">
          <Box>{invite.flow === ' ' ? 'None' : invite.flow}</Box>
          <Box>
            {Array.isArray(invite.dimensions)
              ? invite.dimensions.join(',')
              : invite.dimensions}
          </Box>
        </Text>
      </td>
      <td>
        <Text variant="s">
          {invite.firstName} {invite.lastName}
        </Text>
      </td>
      <td>
        <Text variant="s">{invite.email}</Text>
      </td>
      <td>
        <Text variant="s">{invite.inviteToken}</Text>
      </td>
      <td width="175px">
        <Text variant="s">{addressToString(invite.fromAddress)}</Text>
      </td>
      <td width="175px">
        <Text variant="s">{addressToString(invite.toAddress)}</Text>
      </td>
      <td>
        <ButtonLink
          href={`//consumer-app.staging.updater.com/sign-up?inviteToken=${invite.inviteToken}`}
          target="_blank"
          type="button"
          mt="s"
          size="s"
        >
          Consumer App Claim
        </ButtonLink>
      </td>
      <td width="30px">
        <IconButton
          type="button"
          size="s"
          onClick={removeInvite}
          icon={<X />}
        />
      </td>
    </BodyTR>
  );
};

export const InviteTable = ({
  invites,
  removeInvite,
}: {
  invites: InviteData[];
  removeInvite: (inviteToken: string) => void;
}) => {
  return (
    <Table>
      <thead>
        <tr>
          <TH>
            <Text variant="sBold">Flows</Text>
          </TH>
          <TH>
            <Text variant="sBold">Name</Text>
          </TH>
          <TH>
            <Text variant="sBold">Email</Text>
          </TH>
          <TH>
            <Text variant="sBold">Invite Token</Text>
          </TH>
          <TH>
            <Text variant="sBold">From Address</Text>
          </TH>
          <TH>
            <Text variant="sBold">To Address</Text>
          </TH>
          <TH>
            <Text variant="sBold">Claim URLs</Text>
          </TH>
          <TH>
            <Text variant="sBold">Remove Invite</Text>
          </TH>
        </tr>
      </thead>
      <TBody>
        {invites.map((invite) => (
          <InviteRow
            key={invite.email}
            invite={invite}
            removeInvite={() => removeInvite(invite.inviteToken)}
          />
        ))}
      </TBody>
    </Table>
  );
};
