import { useFormContext } from '@updater/ui-informant';
import { useRouter } from 'next/router';
import { useEffect, useMemo } from 'react';

// source: https://base64.guru/developers/javascript/examples/unicode-strings
/**
 * ASCII to Unicode (decode Base64 to original data)
 * @param {string} b64
 * @return {string}
 */
function atou(b64: string) {
  return decodeURIComponent(escape(atob(b64)));
}
/**
 * Unicode to ASCII (encode data to Base64)
 * @param {string} data
 * @return {string}
 */
function utoa(data: string) {
  return btoa(unescape(encodeURIComponent(data)));
}

function parseFormDataQueryParam(queryParam?: string) {
  try {
    return JSON.parse(atou(queryParam ?? ''));
  } catch (e) {
    console.error(
      'Failed to deserialize. Defaulting to empty form.',
      queryParam
    );
    return null;
  }
}
export function useFormDataQueryParamOnce() {
  const { query } = useRouter();
  const data = useMemo(
    () =>
      typeof query?.formData === 'string'
        ? parseFormDataQueryParam(query.formData)
        : undefined,
    []
  );
  return data;
}
export function FormQueryParamSerializer() {
  const form = useFormContext();
  const router = useRouter();
  let formData = '';
  try {
    formData = utoa(JSON.stringify(form.values));
  } catch (e) {
    console.error('Failed to serialize form data', form.values);
  }
  useEffect(() => {
    const query = {
      ...router.query,
      formData,
    };
    router.replace(
      {
        query,
      },
      undefined,
      { shallow: true }
    );
  }, [formData]);
  return null;
}
