import { useRouter } from 'next/router';
import Link from 'next/link';
import styled from '@emotion/styled';

const MenuContainer = styled.div`
  margin: 0;
  border-width: 0 0 1px 0;
  border-style: solid;
  border-color: #ccc;
`;

const MenuLink = styled.a`
  display: inline-block;
  padding: 15px;
  background-color: #f5f5f5;
  cursor: pointer;
  border-style: solid;
  border-width: 1px;
  border-color: #ccc;
  border-radius: 8px 8px 0 0;
  margin-right: 10px;
  &.active {
    background-color: #fff;
    border-width: 1px 1px 0 1px;
    font-weight: bold;
  }
  &:hover {
    background-color: #e5e5e5;
    border-color: #28293C;
`;

const MenuItem = ({ active, path, name }) => {
  const cls = 'menu' + (active ? ' active' : '');
  return (
    <Link href={path} legacyBehavior>
      <MenuLink className={cls}>{name}</MenuLink>
    </Link>
  );
};

export const Menu = () => {
  const router = useRouter();
  const items = [
    {
      path: '/',
      name: 'Account Creator',
    },
    {
      path: '/provider-qual',
      name: 'Provider App Qual',
    },
  ];
  return (
    <MenuContainer>
      {items.map((item, index) => {
        return (
          <MenuItem
            key={index}
            path={item.path}
            active={item.path === router.pathname}
            name={item.name}
          />
        );
      })}
    </MenuContainer>
  );
};
